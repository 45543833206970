<div #dialog cdkTrapFocus class="login-dialog">
  <h2 class="login-title">login</h2>
  <form class="login-form" (ngSubmit)="signIn()" #form="ngForm">
    <input
      #usernameInput
      autocomplete="username"
      required
      class="login-input"
      type="text"
      placeholder="username"
      [(ngModel)]="model.username" />
    <input
      autocomplete="current-password"
      required
      class="login-input"
      type="password"
      placeholder="password"
      [(ngModel)]="model.password" />
    <div class="login-button-wrap">
      <button class="login-button" [disabled]="form.invalid" type="submit">
        Sign in
      </button>
    </div>
  </form>
</div>
