<span class="pill"
  >{{ content }}
  <span [@growWidth]="hovered ? 'h' : 'nh'">
    <span
      class="remove-wrapper"
      [@fadeInOut]
      (click)="onRemove($event)"
      *ngIf="removeable && hovered">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-square-x remove-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round">
        <desc>
          Download more icon variants from https://tabler-icons.io/i/square-x
        </desc>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <rect x="4" y="4" width="16" height="16" rx="2"></rect>
        <path d="M10 10l4 4m0 -4l-4 4"></path>
      </svg>
    </span>
  </span>
</span>
