<div #root cdkTrapFocus class="{{ class }} app-select-wrapper">
  <div class="app-select-input-wrapper">
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-hash"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round">
        <desc>
          Download more icon variants from https://tabler-icons.io/i/hash
        </desc>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <line x1="5" y1="9" x2="19" y2="9"></line>
        <line x1="5" y1="15" x2="19" y2="15"></line>
        <line x1="11" y1="4" x2="7" y2="20"></line>
        <line x1="17" y1="4" x2="13" y2="20"></line>
      </svg>
    </span>
    <input
      #input
      [(ngModel)]="searchText"
      class="text-input"
      type="text"
      placeholder="{{ placeholder }}"
      (focus)="onFocus($event)" />
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-caret-down"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round">
        <desc>
          Download more icon variants from https://tabler-icons.io/i/caret-down
        </desc>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M18 15l-6 -6l-6 6h12" transform="rotate(180 12 12)"></path>
      </svg>
    </span>
  </div>

  <div class="options-list" *ngIf="showList">
    <ul>
      <li
        tabindex="0"
        [class.hidden]="selected.has(option)"
        *ngFor="let option of options | filterList : searchText"
        (keydown.enter)="onSelect(option)"
        (click)="onSelect(option)">
        {{ option }}
      </li>
    </ul>
  </div>
</div>
